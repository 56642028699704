@import '../../styles/variables';
@import '../../styles/includes';

.NewsCardList {
    $root: &;

    &__Title {
        @extend %h2;
    }

    &__Container {
        @extend %container;
    }

    &__TitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: calc($spacingM + $spacingS);
    }

    &__List {
        display: grid;
        width: calc(100% + 32px);
        padding-right: 16px;
        margin-left: -16px;
        grid-template-columns: repeat(3, minmax(calc(100% - 16px), 0));
        overflow-x: scroll;
        overflow-y: visible;
        scroll-snap-type: x mandatory;

        @include media(ml) {
            width: 100%;
            margin-left: 0;
            padding-right: 0;
            overflow-x: visible;
            overflow-y: visible;
            grid-template-columns: none;

            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-column-gap: 32px;
            grid-row-gap: 32px;
        }

        #{$root}--Row & {
            @include media(ml) {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
            }
        }
    }

    &__Item {
        width: 100%;
        margin-bottom: 24px;
        width: calc(100% - 16px);
        margin-left: 16px;
        scroll-snap-align: center;
        flex: 0 1 auto;

        @include media(ml) {
            width: 100%;
            margin-bottom: 0;
            margin-left: 0;

            &--1 {
                grid-area: 1 / 1 / 5 / 3;
            }

            &--2 {
                grid-area: 1 / 3 / 3 / 5;
            }

            &--3 {
                grid-area: 3 / 3 / 5 / 5;
            }

            &--Row {
                grid-area: unset;
            }
        }
    }
}
