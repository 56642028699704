@import '../../styles/includes';

.NewsCard {
    $root: &;

    height: 100%;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    background-color: $colorWhite;
    display: block;
    color: $colorBlack;
    text-decoration: none;
    transition: box-shadow 0.25s ease;

    @include media(ml) {
        display: flex;
        flex-direction: row;
        padding: 40px 48px;

        &--1 {
            padding: 0;
            display: block;
        }
    }

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 8px 24px -1px rgba(0, 0, 0, 0.5);
        }
    }

    &__ImageWrap {
        position: relative;
        width: 100%;
        height: 192px;
        background-color: $colorWhite;

        @include media(ml) {
            height: 296px;

            #{$root}--3 &,
            #{$root}--2 & {
                display: none;
            }
        }
    }

    &__Top {

        #{$root}--3 &,
        #{$root}--2 & {
            @include media(ml) {
                margin: 0;
            }
        }

    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: calc($spacingL - $spacingS) $spacingM;
        position: relative;
        width: 100%;
        height: calc(100% - 192px);

        @include media(ml) {
            height: 100%;
            padding-right: 88px;

            #{$root}--1 & {
                height: calc(100% - 296px);
                padding: 40px 88px 40px 48px;
            }

            #{$root}--3 &,
            #{$root}--2 & {
                padding: 0 88px 0 0;
            }
        }
    }

    &__Title {
        @extend %h4;
        margin-bottom: 8px;
        transition: color 0.25s ease;
        will-change: color;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }
        }
    }

    &__Preamble {
        @extend %p;
        margin-bottom: 20px;
    }

    &__Date {
        @extend %date;
        display: block;
    }

    &__Arrow {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 20px;
        right: 24px;
        transition: background-color 0.25s ease;
        will-change: background-color;

        @include media(ml) {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            top: 50%;
            transform: translateY(-50%);

            #{$root}--3 &,
            #{$root}--2 & {
                right: 0;
            }

            path {
                transition: fill 0.25s ease;
                will-change: fill;
            }

            @media (hover: hover) {
                #{$root}:hover & {
                    background-color: $colorRed50;

                    path {
                        fill: $colorWhite;
                    }
                }
            }
        }
    }
}