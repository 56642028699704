@import '../../styles/includes';

.PageCard {
    $root: &;

    height: 100%;
    background-color: $colorWhite;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.25s ease;
    will-change: box-shadow;
    display: block;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 8px 24px -1px rgba(0, 0, 0, 0.5);
        }
    }

    &__Title {
        @extend %h4-focus;
        margin-bottom: 16px;
        will-change: color;
        transition: color 0.25 ease;

        #{$root}:hover & {
            color: $colorRed50;
        }
    }

    &__Preamble {
        margin-bottom: 24px;
        font-size: 1.6rem;
        line-height: 1.5;
        font-family: $fontLight;
    }

    &__Date {
        font-size: 1.6rem;
        font-family: $fontLight;
    }

    &__ImageWrap {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
    }

    &__Link {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__LinkText {
        font-family: $fontMedium;
        margin-right: 8px;
        font-size: 1.8rem;
        will-change: color;
        transition: color 0.25 ease;

        @media (hover: hover) {
            #{$root}:hover & {
                color: $colorRed50;
            }

            #{$root}:hover &+span {
                transform: translateX(8px);
            }

            #{$root}:hover &+span path {
                fill: $colorRed50;
            }
        }

        &+span path {
            will-change: fill;
            transition: transform 0.25s ease, fill 0.25s ease;
        }

        &+span {
            transform: translateX(0);
            will-change: transform;
            transition: transform 0.25s ease,
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 16px;

        @include media(m) {
            padding: 24px 32px;
        }
    }

    &__Bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}